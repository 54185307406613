/* eslint-disable max-lines-per-function */
/* eslint-disable react/prop-types */
/* eslint-disable complexity */
/* eslint-disable max-len */
import { DeleteOutlined } from "@ant-design/icons";
import { CommentCardContainer } from "@components/comments/commentCard/commentCardContainer";
import { ContentHeader } from "@components/contentHeader/contentHeader";
import { ContentTabs } from "@components/contentTabs/contentTabs";
import { SelectField } from "@components/field/selectField";
import { Flex } from "@components/flex/flex";
import { HeaderContent } from "@components/headerContent/headerContent";
import { SpinStyle } from "@components/style/globalStyles";
import { UnsavedChanges } from "@components/unsavedChanges/unsavedChanges";
import { useAuth } from "@context/authContext/context";
import { HeaderActions } from "@pages/addChallengesPage/addChallengesPageStyle";
import { languages } from "@pages/translationsPage/constants/languages";
import { getFrontendRoles } from "@utils/applicationUtils";
import { createDefaultValues } from "@utils/createDefaultValues";
import { getEmptyEvent, getEmptyEventProject } from "@utils/emptyItems/emptyEvent";
import { errorsToLanguageErrors } from "@utils/errorsToLanguageErrors";
import { handleError, handleSuccess } from "@utils/form/handlers";
import { getText } from "@utils/getText";
import { isObjectWithKeys } from "@utils/isObjectWithKeys";
import { eventToFormValues, formValuesToEvent, getParticipantsNumber } from "@utils/mappers/eventMapper";
import { translateEventTags } from "@utils/mappers/tagMapper";
import { Badge, Breadcrumb, Button, Card, Collapse, Form, Popconfirm, Select, Spin, Tooltip, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { GetFormTemplatesOverviewQuery } from "graphql2/types";
import React, { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useErrors } from "../../hooks/useErrors";
import { ADD_EVENT_SUBEVENT_VIEWS, ADD_EVENT_TABS, AddEventsPageProps } from "./addEventsPageContainer";
import { AddEventsPageStyle } from "./addEventsPageStyle";
import { EventContentCard, EventContentFormValues } from "./general/eventContentCard";
import { EventCoverImageCard, EventCoverImageFormValues } from "./general/eventCoverImageCard";
import { EventProjectSettingsCard } from "./general/eventProjectSettingsCard/eventProjectSettingsCard";
import { EventSettingsCard, EventSettingsFormValues } from "./general/eventSettingsCard";
import { ParticipantsCardContainer } from "./participants/participantsCardContainer";
import { EventProjectsFormValues, EventSubEventFormValues, EventSubEventsOverviewCard } from "./subEvents/eventSubEventsOverviewCard";
import { SubEventCard } from "./subEvents/subEventCard";

export type ErrorObj = object;

export type SubEventErrors = {
    title?: ErrorObj;
    info?: ErrorObj;
    date?: ErrorObj;
    endDate?: ErrorObj;
};

export type EventErrors = {
    title?: ErrorObj;
    description?: ErrorObj;
    image?: ErrorObj;
    start?: ErrorObj;
    end?: ErrorObj;
    registration?: {
        start?: ErrorObj;
        end?: ErrorObj;
    };
    publishOn?: ErrorObj;
    activityTypes?: ErrorObj;
    subEvents?: SubEventErrors[];
};

export type EventFormValues =
    { id?: string; }
    & EventContentFormValues
    & EventCoverImageFormValues
    & EventSettingsFormValues
    & EventSubEventFormValues
    & EventProjectsFormValues;

export const AddEventsPage: FC<AddEventsPageProps> = props => {
    const intl = useIntl();
    const { projectId } = useAuth();
    const { location, projectsQueryResults, formTemplatesQueryResults, chronoRaceEvents, events, applications, tags, editMode, history, updateEvent, match, addEvent, removeEvent, superAdmin } = props;

    const translatedTags = React.useMemo(
        () => translateEventTags((tags && tags.eventTags) || []),
        [tags]
    );

    const tabs: string[] = [
        ADD_EVENT_TABS.general,
        ADD_EVENT_TABS.subevents,
        ADD_EVENT_TABS.participants,
        ADD_EVENT_TABS.comments
    ];

    const event = React.useMemo(
        () => createDefaultValues(eventToFormValues, getEmptyEvent(), location.state?.import, events?.loading, events?.events?.[0]),
        [events]
    );

    const currentTab = tabs.indexOf(match.params.tab);

    const [selectedLanguage, setSelectedLanguage] = useState<string>(languages[0].code);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const activeProjectsInfoMap = {};
    const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>();
    const [activeSubEventIndex, setActiveSubEventIndex] = useState<string | undefined>();
    const [unsaved, setUnsaved] = React.useState(false);
    const [errors, setErrors] = useErrors();
    const [form] = useForm();
    const [chronoRaceIdForm, setChronoRaceIdForm] = React.useState<number|undefined>(undefined);

    const formTemplates = formTemplatesQueryResults ? formTemplatesQueryResults.formTemplates : [];
    const loadingFormTemplates = formTemplatesQueryResults ? formTemplatesQueryResults.loading : true;
    const roles = getFrontendRoles(applications.applications || []);

    const sortedProjects = React.useMemo(() => {
        const projects = [...(projectsQueryResults?.projects ?? [])];

        projects.sort((a, b) => {
            if (a.name && b.name) {
                return a.name.localeCompare(b.name);
            }
            return 0;
        });
        return projects;
    }, [projectsQueryResults?.projects?.length]);

    const onSubmit = async () => {
        const errMsg = match.params.id ? "event.eventUpdateFailed" : "event.eventAddFailed";
        const successMsg = match.params.id ? "event.eventUpdated" : "event.eventAdded";
        try {
            setIsSaving(true);
            const values: EventFormValues = form.getFieldsValue() as EventFormValues;

            const transformValues = async () => (match.params.id
                ? updateEvent({ variables: { ...formValuesToEvent(values), id: match.params.id } })
                : addEvent({ variables: { ...formValuesToEvent(values) } })
            );

            const response = await transformValues();
            if (response) {
                handleSuccess(intl.formatMessage({ id: successMsg }), () => {
                    history.push("/event/events");
                });
                setIsSaving(false);
            }
        } catch (err) {
            setErrors(err);
            handleError(intl.formatMessage({ id: errMsg }));
            message.error(intl.formatMessage({ id: "error.checkHighlightedFields" }));
            setIsSaving(false);
        }
    };

    const onFailed = (err) => {
        setErrors(err);
    };

    const renderEventTab = (id: string, hasErrors: boolean) => <Badge dot={hasErrors}><FormattedMessage id={id} /></Badge>;

    const renderParticipantsTab = () => (!superAdmin && editMode && (match.params.tab === ADD_EVENT_TABS.participants) && event.id
        ? (
            <ParticipantsCardContainer
                eventId={event.id}
                eventTitle={event.title}
                subEventIds={event.subEvents.map(s => s.id)}
            />
        )
        : null);

    const renderCommentsTab = () => (!superAdmin && editMode && (match.params.tab === ADD_EVENT_TABS.comments) && event.id
        ? (
            <CommentCardContainer
                type="event"
                typeId={event.id}
            />
        )
        : null);

    const eventTabs = () => {
        const { subEvents, ...general } = errors;
        const generalHasErrors = isObjectWithKeys(general);
        const subeventsHasErrors = isObjectWithKeys(subEvents);

        return ([
            renderEventTab(ADD_EVENT_TABS.general, generalHasErrors),
            renderEventTab(ADD_EVENT_TABS.subevents, subeventsHasErrors),
            ...(editMode
                ? [
                    renderEventTab(ADD_EVENT_TABS.participants, false),
                    renderEventTab(ADD_EVENT_TABS.comments, false)
                ]
                : []
            )
        ]);
    };

    const _removeEvent = async (id?: string) => {
        if (!id) {
            message.success(intl.formatMessage({ id: "event.deleted" }));
            history.push("/event/events");
            return;
        }

        const res = await removeEvent({ variables: { id } });
        if (res && !res.errors) {
            message.success(intl.formatMessage({ id: "event.deleted" }));
            history.push("/event/events");
        } else {
            message.error(intl.formatMessage({ id: "event.deleteFailed" }));
        }
    };

    const addProjectToList = (add: (data) => void) => {
        if (!sortedProjects) {
            return;
        }

        add(getEmptyEventProject(selectedProjectId));
    };

    const changeProject = (project: string) => {
        setSelectedProjectId(project);
    };

    const onTabChange = (index: string | number) => {
        history.push(`/event/events/${match.params.id ? `edit/${match.params.id}` : "add"}/${tabs[index]}`);
    };

    const onBlur = () => {
        if (!unsaved) {
            setUnsaved(true);
        }
    };

    const defaultProps = {
        changeSelectedLanguage: setSelectedLanguage,
        disabled: isSaving,
        onSubmit,
        onBlur,
        defaultValues: event
    };

    let eventProjects;
    if (event && !event.projects.length && !superAdmin) {
        eventProjects = [getEmptyEventProject(projectId)];
    } else {
        eventProjects = event?.projects;
    }

    const isNotEditable = React.useMemo(
        () => (
            events?.events?.[0].editable === false ? true : undefined
        ),
        [events?.events?.[0]?.editable]
    );

    // eslint-disable-next-line dot-notation
    const chronoRaceId = React.useMemo(() => chronoRaceIdForm || events?.events?.[0]?.chronoraceEventId, [events?.events?.[0]?.chronoraceEventId, chronoRaceIdForm]);
    const selectedChronoRaceEvent = React.useMemo(() => chronoRaceEvents?.chronoRaceEvents && chronoRaceEvents?.chronoRaceEvents.find((chronoRaceEvent) => chronoRaceEvent.id === Number(chronoRaceId)), [chronoRaceId, chronoRaceEvents]);

    const subEvents = form.getFieldValue("subEvents") || event?.subEvents;
    const activeSubEvent = subEvents && activeSubEventIndex !== undefined && subEvents[activeSubEventIndex];
    const activeSubEventId = activeSubEvent ? activeSubEvent.id : undefined;
    const selectedSubEventErrors = activeSubEventIndex !== undefined && errors.subEvents && errors.subEvents[activeSubEventIndex];

    const subeventsTabActive = match.params.tab === ADD_EVENT_TABS.subevents;
    const subeventsOverviewActive = match.params.mode !== ADD_EVENT_SUBEVENT_VIEWS.add && match.params.mode !== ADD_EVENT_SUBEVENT_VIEWS.edit;

    const renderDeleteButton = () => {
        if (getParticipantsNumber(event, projectId)) {
            return (
                <Tooltip title={<FormattedMessage id="event.cantDelete" />}>
                    <Button
                        disabled
                        className="headerButton"
                        type="default"
                    >
                        <FormattedMessage id="event.delete" />
                    </Button>
                </Tooltip>
            );
        }
        return (
            <Popconfirm
                cancelText="No"
                disabled={isNotEditable}
                okText="Yes"
                placement="bottomRight"
                title={<FormattedMessage id="deleteConfirm" />}
                onConfirm={() => _removeEvent(event.id)}
            >
                <Button
                    className="headerButton"
                    disabled={isNotEditable}
                    type="default"
                >
                    <FormattedMessage id="event.delete" />
                </Button>
            </Popconfirm>
        );
    };

    if (loadingFormTemplates || events?.loading || tags?.loading || applications?.loading) {
        return (<SpinStyle><Spin /></SpinStyle>);
    }

    if (!events?.events?.length && match.params.id) {
        props.history.push("/404");
    }

    return (
        <AddEventsPageStyle>
            <Form
                form={form}
                initialValues={{ ...event, projects: eventProjects }}
                onFinish={onSubmit}
                onFinishFailed={onFailed}
            >
                <HeaderContent>
                    <HeaderActions>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/event/events">
                                    <FormattedMessage id="overview" />
                                </Link>
                            </Breadcrumb.Item>
                            {editMode && (
                                <React.Fragment>
                                    <Breadcrumb.Item>
                                        {getText(event.title, selectedLanguage)}
                                    </Breadcrumb.Item>
                                    {activeSubEventId && activeSubEvent && (
                                        <Breadcrumb.Item>
                                            {getText(activeSubEvent.title, selectedLanguage)}
                                        </Breadcrumb.Item>
                                    )}
                                </React.Fragment>
                            )}
                        </Breadcrumb>
                        <div>
                            {unsaved && <UnsavedChanges key="warning" />}
                            <Button
                                key="button1"
                                htmlType="submit"
                                loading={isSaving}
                                type="primary"
                            >
                                <FormattedMessage id="saveChanges" />
                            </Button>
                            {renderDeleteButton()}
                        </div>
                    </HeaderActions>
                    <ContentTabs
                        currentTab={currentTab}
                        handleTabSelectedLanguage={onTabChange}
                        tabs={eventTabs()}
                    />
                </HeaderContent>
                <EventContentCard
                    {...defaultProps}
                    activeLanguage={selectedLanguage}
                    form={form}
                    hidden={match.params.tab !== ADD_EVENT_TABS.general}
                    isChronoRaceOnNormalAdmin={Boolean(chronoRaceId) && !superAdmin}
                    languageErrors={errorsToLanguageErrors({
                        title: errors.title,
                        description: errors.description
                    })}
                    projectId={projectId}
                />
                <EventCoverImageCard
                    {...defaultProps}
                    disabled={isNotEditable}
                    form={form}
                    hidden={(match.params.tab !== ADD_EVENT_TABS.general) || (Boolean(chronoRaceId) && !superAdmin)}
                />
                <EventSettingsCard
                    {...defaultProps}
                    activeLanguage={selectedLanguage}
                    chronoRaceEvents={(chronoRaceEvents && chronoRaceEvents.chronoRaceEvents) || []}
                    chronoRaceId={chronoRaceId ?? undefined}
                    disabled={isNotEditable}
                    eventTags={translatedTags}
                    form={form}
                    hidden={match.params.tab !== ADD_EVENT_TABS.general}
                    intl={intl}
                    roles={roles}
                    setChronoRaceIdForm={setChronoRaceIdForm}
                    superAdmin={superAdmin}
                />
                {!superAdmin && (eventProjects || []).map((project, key) => (
                    <EventProjectSettingsCard
                        {...defaultProps}
                        activeLanguage={selectedLanguage}
                        eventTags={translatedTags}
                        form={form}
                        formTemplates={formTemplates as GetFormTemplatesOverviewQuery["formTemplates"]}
                        hidden={match.params.tab !== ADD_EVENT_TABS.general}
                        isChronoRaceEvent={Boolean(selectedChronoRaceEvent)}
                        prefix={["projects"]}
                        project={project}
                        projectIndex={key}
                        roles={roles}
                        subEvents={(events?.events && events.events[0].subEvents) ?? []}

                    />
                ))}
                {superAdmin && sortedProjects && (
                    <Card
                        hidden={match.params.tab !== ADD_EVENT_TABS.general}
                        title={<FormattedMessage id="projectsSettings" />}
                    >
                        <Form.List name="projects">
                            {(values, { add, remove }) => {
                                const fetchedProjects = form.getFieldValue("projects");

                                // eslint-disable-next-line no-shadow
                                const header = (index: number, projectId: string) => {
                                    const project = sortedProjects.find(p => p.id === projectId);
                                    return (
                                        <Flex
                                            fullWidth
                                            justifyContent="space-between"
                                        >
                                            <p>{(project && project.name) || `Item ${index + 1}`}</p>
                                            <DeleteOutlined onClick={() => remove(index)} />
                                        </Flex>
                                    );
                                };

                                return (
                                    <React.Fragment>
                                        <ContentHeader
                                            rightFields={[
                                                <Button
                                                    key="button"
                                                    disabled={!selectedProjectId}
                                                    type="primary"
                                                    onClick={() => addProjectToList(add)}
                                                >
                                                    <FormattedMessage id="add" />
                                                </Button>
                                            ]}
                                            title={<FormattedMessage id="projects" />}
                                        />
                                        <SelectField
                                            {...defaultProps}
                                            showSearch
                                            filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            id="activityTypes"
                                            placeholder={<FormattedMessage id="placeholder.pleaseSelect" />}
                                            onChange={changeProject}
                                        >
                                            {sortedProjects.map(a => a.id
                                                && !event.projects.some(p => p.projectId === a.id)
                                                && (
                                                    <Select.Option
                                                        key={a.id}
                                                        value={a.id}
                                                    >
                                                        {a.name}
                                                    </Select.Option>
                                                ))}
                                        </SelectField>
                                        <Collapse accordion>
                                            {fetchedProjects.map((project, key) => (
                                                <Collapse.Panel
                                                    key={project.projectId}
                                                    header={header(key, project.projectId)}
                                                >
                                                    <EventProjectSettingsCard
                                                        {...defaultProps}
                                                        activeLanguage={selectedLanguage}
                                                        applications={applications.applications}
                                                        eventTags={translatedTags}
                                                        form={form}
                                                        formTemplates={formTemplates}
                                                        hidden={match.params.tab !== ADD_EVENT_TABS.general || !project.projectId}
                                                        isChronoRaceEvent={Boolean(chronoRaceId)}
                                                        prefix={[]}
                                                        project={project}
                                                        projectIndex={key}
                                                        roles={roles}
                                                        subEvents={(events?.events && events.events[0].subEvents) ?? []}
                                                        title={activeProjectsInfoMap[project.projectId]}
                                                    />
                                                </Collapse.Panel>
                                            ))}
                                        </Collapse>
                                    </React.Fragment>
                                );
                            }}
                        </Form.List>
                    </Card>
                )}
                <EventSubEventsOverviewCard
                    editContent={(index: number) => setActiveSubEventIndex(`${index}`)}
                    errors={errors.subEvents || []}
                    eventId={event.id as string}
                    form={form}
                    hidden={!subeventsOverviewActive || !subeventsTabActive || Boolean(activeSubEventId)}
                    intl={intl}
                    isNotEditable={isNotEditable}
                />
                <SubEventCard
                    {...defaultProps}
                    activeLanguage={selectedLanguage}
                    activeProjectInfoMap={activeProjectsInfoMap}
                    back={() => setActiveSubEventIndex(undefined)}
                    editSubEventId={activeSubEventId}
                    form={form}
                    hidden={!subeventsTabActive || !activeSubEventId}
                    intl={intl}
                    isNotEditable={isNotEditable}
                    languageErrors={errorsToLanguageErrors({
                        title: selectedSubEventErrors && selectedSubEventErrors.title,
                        info: selectedSubEventErrors && selectedSubEventErrors.info
                    })}
                    selectedChronoRaceEvent={selectedChronoRaceEvent}
                />
                {renderParticipantsTab()}
                {renderCommentsTab()}
            </Form>
        </AddEventsPageStyle>
    );
};
