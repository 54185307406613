/* eslint-disable max-lines */

import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode
} from "@lexical/list";
import { INSERT_EMBED_COMMAND } from "@lexical/react/LexicalAutoEmbedPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import {
    $createHeadingNode,
    $createQuoteNode,
    $isHeadingNode,
    $isQuoteNode,
    HeadingTagType
} from "@lexical/rich-text";
import {
    $getSelectionStyleValueForProperty,
    $isParentElementRTL,
    $patchStyleText,
    $setBlocksType
} from "@lexical/selection";
import { $isTableNode, $isTableSelection } from "@lexical/table";
import {
    $findMatchingParent,
    $getNearestBlockElementAncestorOrThrow,
    $getNearestNodeOfType,
    $isEditorIsNestedEditor,
    mergeRegister
} from "@lexical/utils";
import {
    $createParagraphNode,
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    $isRootOrShadowRoot,
    $isTextNode,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
    COMMAND_PRIORITY_NORMAL,
    ElementFormatType,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    INDENT_CONTENT_COMMAND,
    KEY_MODIFIER_COMMAND,
    LexicalEditor,
    OUTDENT_CONTENT_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND
} from "lexical";
import * as React from "react";
import { Dispatch, useCallback, useEffect, useState } from "react";
import useModal from "../../hooks/useModal";
import DropDown, { DropDownItem } from "../../ui/DropDown";
import DropdownColorPicker from "../../ui/DropdownColorPicker";
import { IS_APPLE } from "../../utils/canUseDom";
import { getSelectedNode } from "../../utils/getSelectedNode";
import { sanitizeUrl } from "../../utils/url";
import { EmbedConfigs } from "../AutoEmbedPlugin";
import { InsertImageDialog } from "../ImagesPlugin";
import FontSize from "./fontSize";

const blockTypeToBlockName = {
    bullet: "Bulleted List",
    h1: "Heading 1",
    h2: "Heading 2",
    h3: "Heading 3",
    h4: "Heading 4",
    h5: "Heading 5",
    h6: "Heading 6",
    number: "Numbered List",
    "extended-paragraph": "Normal",
    "extended-quote": "Quote"
};

const rootTypeToRootName = {
    root: "Root",
    table: "Table"
};

const ELEMENT_FORMAT_OPTIONS: {
  [key in Exclude<ElementFormatType, "">]: {
    icon: string;
    iconRTL: string;
    name: string;
  };
} = {
    center: {
        icon: "center-align",
        iconRTL: "center-align",
        name: "Center Align"
    },
    end: {
        icon: "right-align",
        iconRTL: "left-align",
        name: "End Align"
    },
    justify: {
        icon: "justify-align",
        iconRTL: "justify-align",
        name: "Justify Align"
    },
    left: {
        icon: "left-align",
        iconRTL: "left-align",
        name: "Left Align"
    },
    right: {
        icon: "right-align",
        iconRTL: "right-align",
        name: "Right Align"
    },
    start: {
        icon: "left-align",
        iconRTL: "right-align",
        name: "Start Align"
    }
};

function dropDownActiveClass(active: boolean) {
    if (active) {
        return "active dropdown-item-active";
    }
    return "";
}

function BlockFormatDropDown({
    editor,
    blockType,
    disabled = false
}: {
  blockType: keyof typeof blockTypeToBlockName;
  // eslint-disable-next-line react/no-unused-prop-types
  rootType: keyof typeof rootTypeToRootName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element {
    const formatParagraph = () => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $setBlocksType(selection, () => $createParagraphNode());
            }
        });
    };

    const formatHeading = (headingSize: HeadingTagType) => {
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () => $createHeadingNode(headingSize));
            });
        }
    };

    const formatBulletList = () => {
        if (blockType !== "bullet") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            formatParagraph();
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "number") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            formatParagraph();
        }
    };

    const formatQuote = () => {
        if (blockType !== "extended-quote") {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () => $createQuoteNode());
            });
        }
    };

    return (
        <DropDown
            buttonAriaLabel="Formatting options for text style"
            buttonClassName="toolbar-item block-controls"
            buttonIconClassName={`icon block-type ${blockType}`}
            buttonLabel={blockTypeToBlockName[blockType]}
            disabled={disabled}
        >
            <DropDownItem
                className={`item ${dropDownActiveClass(blockType === "extended-paragraph")}`}
                onClick={formatParagraph}
            >
                <i className="icon paragraph" />
                <span className="text">Normal</span>
            </DropDownItem>
            <DropDownItem
                className={`item ${dropDownActiveClass(blockType === "h1")}`}
                onClick={() => formatHeading("h1")}
            >
                <i className="icon h1" />
                <span className="text">Heading 1</span>
            </DropDownItem>
            <DropDownItem
                className={`item ${dropDownActiveClass(blockType === "h2")}`}
                onClick={() => formatHeading("h2")}
            >
                <i className="icon h2" />
                <span className="text">Heading 2</span>
            </DropDownItem>
            <DropDownItem
                className={`item ${dropDownActiveClass(blockType === "h3")}`}
                onClick={() => formatHeading("h3")}
            >
                <i className="icon h3" />
                <span className="text">Heading 3</span>
            </DropDownItem>
            <DropDownItem
                className={`item ${dropDownActiveClass(blockType === "bullet")}`}
                onClick={formatBulletList}
            >
                <i className="icon bullet-list" />
                <span className="text">Bullet List</span>
            </DropDownItem>
            <DropDownItem
                className={`item ${dropDownActiveClass(blockType === "number")}`}
                onClick={formatNumberedList}
            >
                <i className="icon numbered-list" />
                <span className="text">Numbered List</span>
            </DropDownItem>
            <DropDownItem
                className={`item ${dropDownActiveClass(blockType === "extended-quote")}`}
                onClick={formatQuote}
            >
                <i className="icon quote" />
                <span className="text">Quote</span>
            </DropDownItem>
        </DropDown>
    );
}

export function Divider(): JSX.Element {
    return <div className="divider" />;
}

function ElementFormatDropdown({
    editor,
    value,
    isRTL,
    disabled = false
}: {
  editor: LexicalEditor;
  value: ElementFormatType;
  isRTL: boolean;
  disabled: boolean;
}) {
    const formatOption = ELEMENT_FORMAT_OPTIONS[value || "left"];

    return (
        <DropDown
            buttonAriaLabel="Formatting options for text alignment"
            buttonClassName="toolbar-item spaced alignment"
            buttonIconClassName={`icon ${
                isRTL ? formatOption.iconRTL : formatOption.icon
            }`}
            buttonLabel={formatOption.name}
            disabled={disabled}
        >
            <DropDownItem
                className="item"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                }}
            >
                <i className="icon left-align" />
                <span className="text">Left Align</span>
            </DropDownItem>
            <DropDownItem
                className="item"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                }}
            >
                <i className="icon center-align" />
                <span className="text">Center Align</span>
            </DropDownItem>
            <DropDownItem
                className="item"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                }}
            >
                <i className="icon right-align" />
                <span className="text">Right Align</span>
            </DropDownItem>
            <DropDownItem
                className="item"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                }}
            >
                <i className="icon justify-align" />
                <span className="text">Justify Align</span>
            </DropDownItem>
            <Divider />
            <DropDownItem
                className="item"
                onClick={() => {
                    editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
                }}
            >
                <i className={`icon ${isRTL ? "indent" : "outdent"}`} />
                <span className="text">Outdent</span>
            </DropDownItem>
            <DropDownItem
                className="item"
                onClick={() => {
                    editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
                }}
            >
                <i className={`icon ${isRTL ? "outdent" : "indent"}`} />
                <span className="text">Indent</span>
            </DropDownItem>
        </DropDown>
    );
}

// eslint-disable-next-line complexity
export default function ToolbarPlugin({
    setIsLinkEditMode
}: {
  setIsLinkEditMode: Dispatch<boolean>;
}): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>("extended-paragraph");
    const [rootType, setRootType] = useState<keyof typeof rootTypeToRootName>("root");
    const [fontSize, setFontSize] = useState<string>("14px");
    const [fontColor, setFontColor] = useState<string>("#000");
    const [bgColor, setBgColor] = useState<string>("#fff");
    const [elementFormat, setElementFormat] = useState<ElementFormatType>("left");
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isSubscript, setIsSubscript] = useState(false);
    const [isSuperscript, setIsSuperscript] = useState(false);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [modal, showModal] = useModal();
    const [isRTL, setIsRTL] = useState(false);
    const [isEditable, setIsEditable] = useState(() => editor.isEditable());
    const [isImageCaption, setIsImageCaption] = useState(false);

    const isHeading = blockType === "h1" || blockType === "h2" || blockType === "h3";

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            if (activeEditor !== editor && $isEditorIsNestedEditor(activeEditor)) {
                const rootElement = activeEditor.getRootElement();
                setIsImageCaption(
                    Boolean(rootElement?.parentElement?.classList.contains(
                        "image-caption-container"
                    ))
                );
            } else {
                setIsImageCaption(false);
            }

            const anchorNode = selection.anchor.getNode();
            let element = anchorNode.getKey() === "root"
                ? anchorNode
                : $findMatchingParent(anchorNode, (e) => {
                    const parent = e.getParent();
                    return parent !== null && $isRootOrShadowRoot(parent);
                });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = activeEditor.getElementByKey(elementKey);

            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsSubscript(selection.hasFormat("subscript"));
            setIsSuperscript(selection.hasFormat("superscript"));
            setIsRTL($isParentElementRTL(selection));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            const tableNode = $findMatchingParent(node, $isTableNode);
            if ($isTableNode(tableNode)) {
                setRootType("table");
            } else {
                setRootType("root");
            }

            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(
                        anchorNode,
                        ListNode
                    );
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();

                    setBlockType(type as keyof typeof blockTypeToBlockName);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type as keyof typeof blockTypeToBlockName);
                    }
                }
            }
            // Handle buttons
            setFontColor(
                $getSelectionStyleValueForProperty(selection, "color", "#000")
            );
            setBgColor(
                $getSelectionStyleValueForProperty(
                    selection,
                    "background-color",
                    "#fff"
                )
            );
            let matchingParent;
            if ($isLinkNode(parent)) {
                // If node is a link, we need to fetch the parent paragraph node to set format
                matchingParent = $findMatchingParent(
                    node,
                    (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
                );
            }

            // If matchingParent is a valid node, pass it's format type
            let formatType;

            if ($isElementNode(matchingParent)) {
                formatType = matchingParent.getFormatType();
            } else if ($isElementNode(node)) {
                formatType = node.getFormatType();
            } else {
                formatType = parent?.getFormatType() || "left";
            }

            setElementFormat(formatType);
        }
        if ($isRangeSelection(selection) || $isTableSelection(selection)) {
            setFontSize(
                $getSelectionStyleValueForProperty(selection, "font-size", "14px")
            );
        }
    }, [activeEditor, editor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                setActiveEditor(newEditor);
                $updateToolbar();
                return false;
            },
            COMMAND_PRIORITY_CRITICAL
        );
    }, [editor, $updateToolbar]);

    useEffect(() => {
        activeEditor.getEditorState().read(() => {
            $updateToolbar();
        });
    }, [activeEditor, $updateToolbar]);

    useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener((editable) => {
                setIsEditable(editable);
            }),
            activeEditor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
            activeEditor.registerCommand<boolean>(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL
            ),
            activeEditor.registerCommand<boolean>(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL
            )
        );
    }, [$updateToolbar, activeEditor, editor]);

    useEffect(() => {
        return activeEditor.registerCommand(
            KEY_MODIFIER_COMMAND,
            (payload) => {
                const event: KeyboardEvent = payload;
                const { code, ctrlKey, metaKey } = event;

                if (code === "KeyK" && (ctrlKey || metaKey)) {
                    event.preventDefault();
                    let url: string | null;
                    if (!isLink) {
                        setIsLinkEditMode(true);
                        url = sanitizeUrl("https://");
                    } else {
                        setIsLinkEditMode(false);
                        url = null;
                    }
                    return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
                }
                return false;
            },
            COMMAND_PRIORITY_NORMAL
        );
    }, [activeEditor, isLink, setIsLinkEditMode]);

    const applyStyleText = useCallback(
        (styles: Record<string, string>, skipHistoryStack?: boolean) => {
            activeEditor.update(
                () => {
                    const selection = $getSelection();
                    if (selection !== null) {
                        $patchStyleText(selection, styles);
                    }
                },
                skipHistoryStack ? { tag: "historic" } : {}
            );
        },
        [activeEditor]
    );

    const clearFormatting = useCallback(() => {
        activeEditor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection) || $isTableSelection(selection)) {
                const { anchor } = selection;
                const { focus } = selection;
                const nodes = selection.getNodes();
                const extractedNodes = selection.extract();

                if (anchor.key === focus.key && anchor.offset === focus.offset) {
                    return;
                }

                nodes.forEach((node, idx) => {
                    // We split the first and last node by the selection
                    // So that we don't format unselected text inside those nodes
                    if ($isTextNode(node)) {
                        // Use a separate variable to ensure TS does not lose the refinement
                        let textNode = node;
                        if (idx === 0 && anchor.offset !== 0) {
                            textNode = textNode.splitText(anchor.offset)[1] || textNode;
                        }
                        if (idx === nodes.length - 1) {
                            textNode = textNode.splitText(focus.offset)[0] || textNode;
                        }
                        /**
             * If the selected text has one format applied
             * selecting a portion of the text, could
             * clear the format to the wrong portion of the text.
             *
             * The cleared text is based on the length of the selected text.
             */
                        // We need this in case the selected text only has one format
                        const extractedTextNode = extractedNodes[0];
                        if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
                            textNode = extractedTextNode;
                        }

                        if (textNode.__style !== "") {
                            textNode.setStyle("");
                        }
                        if (textNode.__format !== 0) {
                            textNode.setFormat(0);
                            $getNearestBlockElementAncestorOrThrow(textNode).setFormat("");
                        }
                        // eslint-disable-next-line no-param-reassign
                        node = textNode;
                    } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
                        node.replace($createParagraphNode(), true);
                    } else if ($isDecoratorBlockNode(node)) {
                        node.setFormat("");
                    }
                });
            }
        });
    }, [activeEditor]);

    const onFontColorSelect = useCallback(
        (value: string, skipHistoryStack: boolean) => {
            applyStyleText({ color: value }, skipHistoryStack);
        },
        [applyStyleText]
    );

    const onBgColorSelect = useCallback(
        (value: string, skipHistoryStack: boolean) => {
            applyStyleText({ "background-color": value }, skipHistoryStack);
        },
        [applyStyleText]
    );

    const insertLink = useCallback(() => {
        if (!isLink) {
            setIsLinkEditMode(true);
            activeEditor.dispatchCommand(
                TOGGLE_LINK_COMMAND,
                sanitizeUrl("https://")
            );
        } else {
            setIsLinkEditMode(false);
            activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [activeEditor, isLink, setIsLinkEditMode]);

    const canViewerSeeInsertDropdown = !isImageCaption;
    const canViewerSeeInsertCodeButton = !isImageCaption;

    return (
        <div className="toolbar">
            <button
                aria-label="Undo"
                className="toolbar-item spaced"
                disabled={!canUndo || !isEditable}
                title={IS_APPLE ? "Undo (⌘Z)" : "Undo (Ctrl+Z)"}
                type="button"
                onClick={() => {
                    activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
                }}
            >
                <i className="format undo" />
            </button>
            <button
                aria-label="Redo"
                className="toolbar-item"
                disabled={!canRedo || !isEditable}
                title={IS_APPLE ? "Redo (⇧⌘Z)" : "Redo (Ctrl+Y)"}
                type="button"
                onClick={() => {
                    activeEditor.dispatchCommand(REDO_COMMAND, undefined);
                }}
            >
                <i className="format redo" />
            </button>
            <Divider />
            {blockType in blockTypeToBlockName && activeEditor === editor && (
                <React.Fragment>
                    <BlockFormatDropDown
                        blockType={blockType}
                        disabled={!isEditable}
                        editor={activeEditor}
                        rootType={rootType}
                    />
                    <Divider />
                </React.Fragment>
            )}
            <Divider />
            <FontSize
                disabled={!isEditable}
                editor={activeEditor}
                selectionFontSize={fontSize.slice(0, -2)}
            />
            <Divider />
            <button
                aria-label={`Format text as bold. Shortcut: ${
                    IS_APPLE ? "⌘B" : "Ctrl+B"
                }`}
                className={`toolbar-item spaced ${isBold ? "active" : ""}`}
                disabled={!isEditable || isHeading}
                title={IS_APPLE ? "Bold (⌘B)" : "Bold (Ctrl+B)"}
                type="button"
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                }}
            >
                <i className="format bold" />
            </button>
            <button
                aria-label={`Format text as italics. Shortcut: ${
                    IS_APPLE ? "⌘I" : "Ctrl+I"
                }`}
                className={`toolbar-item spaced ${isItalic ? "active" : ""}`}
                disabled={!isEditable || isHeading}
                title={IS_APPLE ? "Italic (⌘I)" : "Italic (Ctrl+I)"}
                type="button"
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                }}
            >
                <i className="format italic" />
            </button>
            <button
                aria-label={`Format text to underlined. Shortcut: ${
                    IS_APPLE ? "⌘U" : "Ctrl+U"
                }`}
                className={`toolbar-item spaced ${isUnderline ? "active" : ""}`}
                disabled={!isEditable || isHeading}
                title={IS_APPLE ? "Underline (⌘U)" : "Underline (Ctrl+U)"}
                type="button"
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                }}
            >
                <i className="format underline" />
            </button>
            {canViewerSeeInsertCodeButton && (
                <button
                    aria-label="Insert code block"
                    className="toolbar-item spaced"
                    disabled={!isEditable || isHeading}
                    title="Insert code block"
                    type="button"
                    onClick={() => {
                        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
                    }}
                >
                    <i className="format code" />
                </button>
            )}
            <button
                aria-label="Insert link"
                className={`toolbar-item spaced ${isLink ? "active" : ""}`}
                disabled={!isEditable || isHeading}
                title="Insert link"
                type="button"
                onClick={insertLink}
            >
                <i className="format link" />
            </button>
            <DropdownColorPicker
                buttonAriaLabel="Formatting text color"
                buttonClassName="toolbar-item color-picker"
                buttonIconClassName="icon font-color"
                color={fontColor}
                disabled={!isEditable}
                title="text color"
                onChange={onFontColorSelect}
            />
            <DropdownColorPicker
                buttonAriaLabel="Formatting background color"
                buttonClassName="toolbar-item color-picker"
                buttonIconClassName="icon bg-color"
                color={bgColor}
                disabled={!isEditable}
                title="bg color"
                onChange={onBgColorSelect}
            />
            <DropDown
                buttonAriaLabel="Formatting options for additional text styles"
                buttonClassName="toolbar-item spaced"
                buttonIconClassName="icon dropdown-more"
                buttonLabel=""
                disabled={!isEditable || isHeading}
            >
                <DropDownItem
                    aria-label="Format text with a strikethrough"
                    className={`item ${dropDownActiveClass(isStrikethrough)}`}
                    title="Strikethrough"
                    onClick={() => {
                        activeEditor.dispatchCommand(
                            FORMAT_TEXT_COMMAND,
                            "strikethrough"
                        );
                    }}
                >
                    <i className="icon strikethrough" />
                    <span className="text">Strikethrough</span>
                </DropDownItem>
                <DropDownItem
                    aria-label="Format text with a subscript"
                    className={`item ${dropDownActiveClass(isSubscript)}`}
                    title="Subscript"
                    onClick={() => {
                        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript");
                    }}
                >
                    <i className="icon subscript" />
                    <span className="text">Subscript</span>
                </DropDownItem>
                <DropDownItem
                    aria-label="Format text with a superscript"
                    className={`item ${dropDownActiveClass(isSuperscript)}`}
                    title="Superscript"
                    onClick={() => {
                        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript");
                    }}
                >
                    <i className="icon superscript" />
                    <span className="text">Superscript</span>
                </DropDownItem>
                <DropDownItem
                    aria-label="Clear all text formatting"
                    className="item"
                    title="Clear text formatting"
                    onClick={clearFormatting}
                >
                    <i className="icon clear" />
                    <span className="text">Clear Formatting</span>
                </DropDownItem>
            </DropDown>
            {canViewerSeeInsertDropdown && (
                <React.Fragment>
                    <Divider />
                    <DropDown
                        buttonAriaLabel="Insert specialized editor node"
                        buttonClassName="toolbar-item spaced"
                        buttonIconClassName="icon plus"
                        buttonLabel="Insert"
                        disabled={!isEditable}
                    >
                        <DropDownItem
                            className="item"
                            onClick={() => {
                                activeEditor.dispatchCommand(
                                    INSERT_HORIZONTAL_RULE_COMMAND,
                                    undefined
                                );
                            }}
                        >
                            <i className="icon horizontal-rule" />
                            <span className="text">Horizontal Rule</span>
                        </DropDownItem>
                        <DropDownItem
                            className="item"
                            onClick={() => {
                                showModal("Insert Image", (onClose: any) => (
                                    <InsertImageDialog
                                        activeEditor={activeEditor}
                                        onClose={onClose}
                                    />
                                ));
                            }}
                        >
                            <i className="icon image" />
                            <span className="text">Image</span>
                        </DropDownItem>
                        {EmbedConfigs.map((embedConfig) => (
                            <DropDownItem
                                key={embedConfig.type}
                                className="item"
                                onClick={() => {
                                    activeEditor.dispatchCommand(
                                        INSERT_EMBED_COMMAND,
                                        embedConfig.type
                                    );
                                }}
                            >
                                {embedConfig.icon}
                                <span className="text">{embedConfig.contentName}</span>
                            </DropDownItem>
                        ))}
                    </DropDown>
                </React.Fragment>
            )}
            <Divider />
            <ElementFormatDropdown
                disabled={!isEditable}
                editor={activeEditor}
                isRTL={isRTL}
                value={elementFormat}
            />

            {modal}
        </div>
    );
}
