import { FieldInfo } from "@components/field/fieldStyle";
import { Flex } from "@components/flex/flex";
import { DescriptionTooltip } from "@components/tooltip/descriptionTooltip";
import { AutoComplete, Form, Select } from "antd";
import { FormItemProps, RuleObject } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import * as React from "react";
import styled from "styled-components";

export interface FieldProps extends Omit<SelectProps<string | string[]>, "value" | "defaultValue">, Pick<FormItemProps, "rules" | "initialValue" | "name" | "dependencies"> {
    info?: React.ReactNode;
    inline?: boolean;
    hidden?: boolean;
    label?: React.ReactNode;
    shouldUpdate?: boolean;
    required?: boolean;
    description?: string;
    fullWidth?: boolean;
    autoComplete?: boolean;
}

export const SelectFieldStyle = styled(Flex)`
  display: flex;

  div[role=alert] > span {
    color: #CF4948;
  }

`;

export const SelectFieldLabel = styled.label`
    margin-right: 1rem;
    display: flex;
    position: relative;
`;

export const SelectField: React.FC<FieldProps> = (props) => {
    const {
        children, initialValue, name, rules, inline,
        dependencies, shouldUpdate, description, fullWidth,
        mode, autoComplete, ...selectProps
    } = props;

    const isRequired = selectProps.required || (rules || []).find((rule: RuleObject) => rule.required === true);

    const selectComponentProps = {
        getPopupContainer: (trigger: HTMLElement) => trigger,
        showArrow: true,
        ...selectProps,
        style: fullWidth ? { width: "100%" } : undefined,
        virtual: false
    };

    return (
        <SelectFieldStyle direction={inline ? "row" : "column"}>
            {!selectProps.hidden && selectProps.label && (
                <SelectFieldLabel
                    className={isRequired ? "isRequired" : ""}
                    htmlFor={selectProps.id}
                >
                    {selectProps.label}
                    {!selectProps.hidden && description && <DescriptionTooltip description={description} />}
                </SelectFieldLabel>
            )}
            {!selectProps.hidden && selectProps.info && <FieldInfo>{selectProps.info}</FieldInfo>}
            <Form.Item
                dependencies={dependencies}
                initialValue={initialValue}
                name={name}
                rules={!selectProps.disabled ? rules : undefined}
                shouldUpdate={shouldUpdate}
            >
                {
                    autoComplete
                        ? (
                            <AutoComplete
                                {...selectComponentProps}
                            >
                                {children}
                            </AutoComplete>
                        )
                        : (
                            <Select
                                {...selectComponentProps}
                                mode={mode}
                            >
                                {children}
                            </Select>
                        )
                }
            </Form.Item>
        </SelectFieldStyle>
    );
};
