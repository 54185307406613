/* eslint-disable react/jsx-no-bind */

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { useLexicalEditable } from "@lexical/react/useLexicalEditable";
import * as React from "react";
import { useEffect, useState } from "react";
import { CAN_USE_DOM } from "./utils/canUseDom";

import { useSharedHistoryContext } from "./context/SharedHistoryContext";
import AutoEmbedPlugin from "./plugins/AutoEmbedPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ComponentPickerPlugin from "./plugins/ComponentPickerPlugin";
import DebouncedLexicalOnChangePlugin from "./plugins/DebounceOnChangePlugin";
import DragDropPaste from "./plugins/DragDropPastePlugin";
import DraggableBlockPlugin from "./plugins/DraggableBlockPlugin";
import EmojiPickerPlugin from "./plugins/EmojiPickerPlugin";
import EmojisPlugin from "./plugins/EmojisPlugin";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import ImagesPlugin from "./plugins/ImagesPlugin";
import LinkPlugin from "./plugins/LinkPlugin";
import SetInitialValuePlugin from "./plugins/SetInitialValuePlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import YouTubePlugin from "./plugins/YouTubePlugin";
import ContentEditable from "./ui/ContentEditable";

interface EditorProps {
    initialValue: string;
    onBlur?(): void;
    onChange(value: string): void;
    disabled?: boolean;
}

export default function Editor({
    initialValue,
    onBlur,
    onChange,
    disabled
}: EditorProps) {
    const { historyState } = useSharedHistoryContext();
    const debugMode = false;
    const isEditable = useLexicalEditable();
    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
    const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);
    const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    useEffect(() => {
        const updateViewPortWidth = () => {
            const isNextSmallWidthViewport = CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;

            if (isNextSmallWidthViewport !== isSmallWidthViewport) {
                setIsSmallWidthViewport(isNextSmallWidthViewport);
            }
        };
        updateViewPortWidth();
        window.addEventListener("resize", updateViewPortWidth);

        return () => {
            window.removeEventListener("resize", updateViewPortWidth);
        };
    }, [isSmallWidthViewport]);

    return (
        <React.Fragment>
            <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
            {/* // TODO remove tree view */}
            <div className={`editor-container ${debugMode ? "tree-view" : ""}`}>
                <SetInitialValuePlugin initHtml={initialValue} />
                <DebouncedLexicalOnChangePlugin
                    debounceTime={200}
                    onChange={onChange}
                />
                <DragDropPaste />
                <AutoFocusPlugin />
                <ClearEditorPlugin />
                <ComponentPickerPlugin />
                <EmojiPickerPlugin />
                <AutoEmbedPlugin />
                <EmojisPlugin />
                <HashtagPlugin />
                <AutoLinkPlugin />
                <HistoryPlugin externalHistoryState={historyState} />
                <RichTextPlugin
                    contentEditable={(
                        <div className="editor-scroller">
                            <div
                                ref={onRef}
                                className="editor"
                            >
                                <ContentEditable
                                    disabled={disabled}
                                    placeholder="Enter some rich text..."
                                    onBlur={onBlur}
                                />
                            </div>
                        </div>
                    )}
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <ListPlugin />
                <CheckListPlugin />
                <ImagesPlugin />
                <LinkPlugin />
                <YouTubePlugin />
                <ClickableLinkPlugin disabled={isEditable} />
                <HorizontalRulePlugin />
                <TabIndentationPlugin />
                {floatingAnchorElem && !isSmallWidthViewport && (
                    <React.Fragment>
                        <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                        <FloatingLinkEditorPlugin
                            anchorElem={floatingAnchorElem}
                            isLinkEditMode={isLinkEditMode}
                            setIsLinkEditMode={setIsLinkEditMode}
                        />
                        <FloatingTextFormatToolbarPlugin
                            anchorElem={floatingAnchorElem}
                            setIsLinkEditMode={setIsLinkEditMode}
                        />
                    </React.Fragment>
                )}
            </div>
            {debugMode && <TreeViewPlugin />}
        </React.Fragment>
    );
}
